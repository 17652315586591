export default function AppHead() {
    return (
        <div>
            <title>The Bullish Trade!</title>
            <meta property="og:title" content="The Bullish Trade" key="title"/>
            <meta name="description" content="Options trader's secret toolkit"/>

            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://thebullish.trade"/>
            <meta property="og:title" content="The Bullish Trade"/>
            <meta property="og:description" content="Options trader's secret toolkit"/>
            <meta property="og:image" content="https://thebullish.trade/graphics/1024.jpg"/>

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://thebullish.trade"/>
            <meta property="twitter:title" content="The Bullish Trade"/>
            <meta property="twitter:description" content="Options trader's secret toolkit"/>
            <meta property="twitter:image" content="https://thebullish.trade/graphics/1024.jpg"/>

        </div>
    )
}