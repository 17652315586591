const handleScroll = (setVisibleHeader: Function, setPrevScrollPos: Function) => {
    const currentScrollPos = window.scrollY

    if (currentScrollPos > 300) {
        setVisibleHeader(true);
    } else {
        setVisibleHeader(false);
    }
    // if (currentScrollPos > prevScrollPos) {
    //     setVisibleHeader(false)
    // } else {
    //     setVisibleHeader(true)
    // }

    setPrevScrollPos(currentScrollPos)
}

export { handleScroll }