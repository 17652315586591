import {navigateToApp} from "~/components/navigate-to-top/navigate-to-top";
import AppConstants from "~/components/constants/app_constants";
import {useEffect, useState} from "react";
import posthog from "posthog-js";

export default function FloatingTryForFree(props: any) {
    const [tryFreeDownloadsPage, setTryFreeDownloadsPage] = useState(false)
    useEffect(() => {
        setTryFreeDownloadsPage(posthog.getFeatureFlag('try_free_downloads_page') === 'test');
    });

    return (
        <nav className={`z-50 fixed right-5 bottom-0 ${props.visibleHeader ? 'block' : 'hidden'} `}>
            {/*<div onClick={() => navigateToApp("https://app.thebullish.trade", "app", "top-try-now")}*/}
            <div onClick={() => navigateToApp(tryFreeDownloadsPage ? "https://thebullish.trade/downloads" : "https://app.thebullish.trade",
                    tryFreeDownloadsPage ? "downloads" : "app", "top-try-now")}
                 className={"drop-shadow-xl cursor-pointer btn-secondary-grad text-white px-4 py-2 text-xl font-bold rounded-xl mb-3 text-center"}>
                Try for free
            </div>
            <div>
                <img src={"powell.png"} style={{width: 180, height: 120, objectFit: 'contain' }}/>
            </div>
        </nav>
    )
}